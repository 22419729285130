import React from 'react';
import { AppBar, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ChangePassword from '../Customs/ChangePassword'
import Button from '@material-ui/core/Button';
import { useLocale, useSetLocale } from 'react-admin'


const MyAppBar = props => {
    const translate = useTranslate()
    const locale = useLocale()
    const setLocale = useSetLocale()

    return (
        <AppBar {...props} logout={null}>
            <Typography
                variant="h6"
                color="inherit"
                style={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}
            >
                {"Articles"}
                {/* {`${translate('ra.customs.dashboard_title')}`} */}
            </Typography>
            {/* {
                ['fr', 'en', 'de', 'it', 'es', 'nl', 'pt'].map(elem => (
                    <Button style={{ margin: "5px", color: "white" }}
                        disabled={locale === elem}
                        onClick={() => {
                        }}
                    >
                        {elem.toUpperCase()}
                    </Button>
                ))
            } */}
            {/* <Select
                variant="outlined"
                style={{ margin: "5px", color: "white" }}
                value={locale}
                onChange={(event) => {
                    setLocale(event.target.value)
                    localStorage.setItem('locale', event.target.value)
                }}
                label="Locale"
            >
                {
                    ['fr', 'en', 'de', 'it', 'es', 'nl', 'pt'].map(elem => (
                        <MenuItem value={elem}>{elem.toUpperCase()}</MenuItem>
                    ))
                }
            </Select> */}
        </AppBar>
    )
}

export default MyAppBar;
