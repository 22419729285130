
// in app.js
import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import { i18nProvider as polyglotI18nProvider } from './polyglotProvider'
import Theme from './component/Theme'
import Layout from './component/Layout'
import Menu from './component/Menu'
import CommandPage from './pages/Command'
import CommandSuccessPage from './pages/CommandSuccess'
import OrderPage from "./pages/Order";

import { PostList, PostShow, PostIcon } from './resources/post'

import { BrowserRouter as Router, Route } from "react-router-dom";


import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './createAdminStore';

const history = createHashHistory();


class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <Admin
          theme={Theme}
          menu={Menu}
          dataProvider={dataProvider}
          // authProvider={authProvider}
          // dashboard={Dashboard(this.state.dashboard)}
          i18nProvider={polyglotI18nProvider}
          history={history}
          layout={Layout}
          customRoutes={[
            <Route exact path="/order" component={CommandPage} noLayout />,
            <Route exact path="/order-success" component={CommandSuccessPage} noLayout />,
            <Route exact path="/see-order" component={OrderPage} noLayout />,
          ]}
        >
          <Resource name="Post" options={{ group: "Post" }} list={PostList} icon={PostIcon} remove={DeleteButton} />
        </Admin>
      </Provider>
    )
  }
}

export default App
