
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'

import { loadStripe } from '@stripe/stripe-js';
import { v4 as uuid } from 'uuid';


const API_URL = process.env.REACT_APP_API_HOSTNAME
const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const mapGet = {
    'Post': 'user/posts',
}

const mapPost = {
    'Post': 'user/post',
}


const { fetchJson } = fetchUtils

const formify = (params) => {
    const formData = new FormData();
    for (let i in params.data) {
        if (params.data[i] && params.data[i].rawFile) {
            formData.append(i, params.data[i].rawFile);
        } else {
            if (Array.isArray(params.data[i]))
                formData.append(i, JSON.stringify(params.data[i]));
            else
                formData.append(i, params.data[i]);
        }
    }
    return formData;
};

const dataProvider = {
    getList: (resource, params) => new Promise(resolve => {
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data,
            //         total: json.total
            //     })
            // }
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getOne: (resource, params) => new Promise(resolve => {
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data[0],
            //     })
            // }
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(resolve => {
        console.log('create', { resource, params })
        if (resource === 'Professor') {
            // params.data.lastCrawlDate = new Date(params.data.lastCrawlDate).getTime()
        }
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'POST'
        if (resource === 'Dynamicplatform' || resource === 'Doodle') {
            options.body = formify(params)
        } else {
            options.body = JSON.stringify(params.data)
        }
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(resolve => {
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(resolve => {
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    customs: {
        proceedToCheckout: (body) => new Promise(async (resolve) => {
            const stripe = await stripePromise;
            const requestHeaders = new Headers()
            let token = localStorage.getItem('ano-token')
            if (!token) {
                token = uuid()
                localStorage.setItem('ano-token', token)
            }
            requestHeaders.set('Authorization', 'Bearer ' + token)
            requestHeaders.set('Content-Type', 'application/json')
            const response = await fetch(`${API_URL}/auser/create-checkout-session`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify(body)
            });
            const session = await response.json();
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
            }

            resolve()
        }),
        recoverCheckout: (session_id) => new Promise(async (resolve) => {
            const requestHeaders = new Headers()
            requestHeaders.set('Content-Type', 'application/json')
            fetch(`${process.env.REACT_APP_API_HOSTNAME}/auser/recover-checkout-session`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({
                    session_id
                })
            }).then((res) => {
                return res.json()
            }).then((json) => {
                resolve(json)
            })
        }),
        getOrder: (ref) => new Promise(resolve => {
            const url = `${API_URL}/user/post/${ref}`
            const requestHeaders = new Headers()
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve({
                    data: json.data,
                    total: json.total
                })
            })
        }),
    }
}

export default dataProvider
