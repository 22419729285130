import React, { Component } from 'react';
import dataProvider from '../dataProvider';

// import Desktop from './responsive/Desktop'
// import Mobile from './responsive/Mobile'

// import '../scss/home.scss';

class SuccessPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const session_id = new URLSearchParams(window.location.search).get("session_id")
      dataProvider.customs.recoverCheckout(session_id).then((data) => {
        console.log(data)
        window.location = `?ref=${data.command.ref}#/see-order`
      })
    }, 2000)
  }

  render() {
    return (
      <div style={{
        width: "100%",
        textAlign: "center"
      }}>
      </div>
    )
  }
}

export default SuccessPage;
