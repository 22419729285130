// In theme.js
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "rgb(72, 129, 200)",
    },
    secondary: {
      main: "rgb(24, 39, 200)",
    },
  })
);


const rawTheme = {
    palette,
};

const theme = createMuiTheme(
  merge({}, defaultTheme, rawTheme)
);

export default theme