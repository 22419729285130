import React, { Component } from 'react';
import dataProvider from '../dataProvider';

// import Desktop from './responsive/Desktop'
// import Mobile from './responsive/Mobile'

// import '../scss/home.scss';

class OrderPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const ref = new URLSearchParams(window.location.search).get("ref")
      dataProvider.customs.getOrder(ref).then((res) => {
        console.log(res)
        this.setState({ post: res.data[0] })
      })
    }, 2000)
  }

  render() {
    if (!this.state.post)
      return <></>
    return (
      <div style={{
        width: "100%",
        textAlign: "center"
      }}>
        <h1>{this.state.post.title}</h1>
        <div>
          <img style={{ width: "400px" }} src={this.state.post.preview_image} />
        </div>
        <div style={{ width: "1200px", margin: 'auto' }} dangerouslySetInnerHTML={{ __html: this.state.post.content }}></div>
      </div>
    )
  }
}

export default OrderPage;
