
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
    FunctionField,
    FileInput,
    FileField,
    translate,
    SearchInput,
    ImageField,
    RichTextField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/Book'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import UpdateInpiButton from '../Customs/UpdateInpiButton'
import ApiSelectInput from '../Customs/ApiSelectInput'
import BuyButton from '../Customs/BuyButton'
import RichTextInput from 'ra-input-rich-text';

export const PostIcon = BookIcon

const Post_Filter = (props) => (
    <Filter {...props}>
        <SearchInput source="title" alwaysOn />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/* <CreateButton basePath={basePath} /> */}
        </TopToolbar>
    )
}

export const PostList = (props) => (
    <List {...props} filters={<Post_Filter />} actions={<ListActions />} perPage={50} bulkActionButtons={false} pagination={<Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />} >
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.title : ''}
                />
            }
            medium={
                <Datagrid isRowExpandable={false} isRowSelectable={false}>
                    <TextField source="title" sortable={true} />
                    <ChipField source="category" sortable={false} />
                    <TextField source="count_words" sortable={false} />
                    <FunctionField label={"Prix"} render={(record) => record.price + '€'} />
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <BuyButton />
                    {/* <DateField source="updatedAt" showTime={true} sortable={true} /> */}
                    {/* <EditButton />
                    <ShowButton /> */}
                </Datagrid>
            }
        />
    </List>
)


// export const PostShow = (props) => (
//     <Show {...props}>
//         <SimpleShowLayout>
//             <TextField source="title" />
//             <ChipField source="category" />
//             <TextField source="count_words" />
//             <TextField source="price" />
//             {/* <RichTextField source="content" /> */}
//             <DateField source="createdAt" showTime={true} />
//             <DateField source="updatedAt" showTime={true} />
//         </SimpleShowLayout>
//     </Show>
// )

// const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

// export const PostEdit = (props) => (
//     <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
//         <SimpleForm redirect="show">
//             <TextInput source="title" validate={required()} />
//             <TextInput source="category" validate={required()} />
//             <NumberInput source="count_words" validate={required()} />
//             <NumberInput source="scoreYTG" validate={required()} />
//             <NumberInput source="spam_score" validate={required()} />
//             <NumberInput source="price" validate={required()} />
//             <TextInput source="state" validate={required()} />
//             <RichTextInput source="content" validate={required()} />
//         </SimpleForm>
//     </Edit>
// )

// export const PostCreate = (props) => (
//     <Create {...props}>
//         <SimpleForm redirect="show">
//             <TextInput source="title" validate={required()} />
//             <TextInput source="category" validate={required()} />
//             <NumberInput source="count_words" validate={required()} />
//             <NumberInput source="scoreYTG" validate={required()} />
//             <NumberInput source="spam_score" validate={required()} />
//             <NumberInput source="price" validate={required()} />
//             <TextInput source="state" validate={required()} />
//             <RichTextInput source="content" validate={required()} />
//         </SimpleForm>
//     </Create>
// )
